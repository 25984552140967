import resume from "../../../static/mike-resume-frontend.pdf";
/* eslint-disable import/prefer-default-export */
export const navData = [
  {
    title: "Blog",
    url: "",
    type: "link",
    to: "blog",
  },
  {
    title: "Projects",
    url: "",
    type: "link",
    to: "projects",
  },
  {
    title: "Resume",
    url: resume,
    type: "link",
    to: "",
  },
  {
    title: "My Code",
    url: "https://github.com/mikeytown19",
    type: "button",
    to: "",
  },
];
