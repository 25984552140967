import { css } from '../../../stitches.config';

export const colorVariants = css({
  variants: {
    color: {
      red: {
        color: '$red11',
      },
      crimson: {
        color: '$crimson11',
      },
      pink: {
        color: '$pink11',
      },
      purple: {
        color: '$purple11',
      },
      violet: {
        color: '$violet11',
      },
      indigo: {
        color: '$indigo11',
      },
      blue: {
        color: '$blue11',
      },
      turquoise: {
        color: '$turquoise11',
      },
      teal: {
        color: '$teal11',
      },
      green: {
        color: '$green11',
      },
      lime: {
        color: '$lime11',
      },
      yellow: {
        color: '$yellow9',
      },
      orange: {
        color: '$orange11',
      },
      gold: {
        color: '$gold11',
      },
      bronze: {
        color: '$bronze11',
      },
      gray: {
        color: '$gray11',
      },
      contrast: {
        color: '$hiContrast',
      },
      white: {
        color: '$whiteA12',
      },
    },
  },
});

export const backgroundColorVariants = css({
  variants: {
    bg: {
      primary: {
        bg: '$primary',
      },
      base: {
        bg: '$base',
      },
      warning: {
        bg: '$warning',
      },
      success: {
        bg: '$success',
      },
      tertiary: {
        bg: '$tertiary',
      },
      bruner: {
        bg: '$bruner',
      },
      light3: {
        bg: '$light3',
      },
      light2: {
        bg: '$light2',
      },
    },
  },
});
