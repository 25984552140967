import React from 'react'
import { styled } from '../../../stitches.config'

export const Button = styled('button', {
  margin: '0',
  color: '$light1',
  bg: '$primary',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  py: '$3',
  px: '$5',
  font: 'inherit',
  fontSize: '$3',
  border: '0',
  fontWeight: '$bold',
  borderRadius: '$1',
  cursor: 'pointer',
  WebkitAppearance: 'button',
  '> *': { pointerEvents: 'none' },
  textDecoration: 'none',
  '&:hover': { boxShadow: '$md' },
})
