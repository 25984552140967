import React from 'react';
import { Link } from 'react-scroll';
import { Link as GatsbyLink } from 'gatsby';
import { SunIcon, MoonIcon } from '@radix-ui/react-icons';
import * as Styled from './Header.styles';

import { Button } from '../Button';
import Logo from '../../images/logo.svg';
import { navData } from './navData';
import { Typography } from '../Typography';
import { ThemeContext } from '../../styles/ThemeProvider';

const DarkModeToggle = () => {
  // Don't render anything at compile time. Deferring rendering until we
  // know which theme to use on the client avoids incorrect initial
  // state being displayed.
  const { colorMode, setColorMode } = React.useContext(ThemeContext);
  if (!colorMode) {
    return null;
  }

  const nextColor = colorMode !== 'dark' ? 'dark' : 'light';

  return (
    <Styled.SvgContainer>
      <Typography as="label">
        <Typography
          as="input"
          type="checkbox"
          checked={colorMode !== 'dark'}
          onChange={() => setColorMode(nextColor)}
        />
        {colorMode !== 'dark' ? <MoonIcon /> : <SunIcon />}
      </Typography>
    </Styled.SvgContainer>
  );
};

export const Header = ({ nolinks }) => (
  <Styled.Header>
    <Styled.HeaderContainer>
      <Styled.MotionLogo className="logo">
        <GatsbyLink to="/">
          <Logo />
        </GatsbyLink>
      </Styled.MotionLogo>
      <Styled.MotionDiv>
        <Styled.MotionItemContainr
          nolinks={nolinks}
          css={{
            jc: !nolinks ? 'space-around' : 'flex-end',
          }}
        >
          {!nolinks
              && navData.map(({
                title, type, to, url,
              }) => (type === 'link' ? (
                <Styled.MotionItem key={title} className="item">
                  {!url ? (
                    <Link
                      key={title}
                      as="a"
                      to={to}
                      activeClass="active"
                      spy
                      smooth
                      offset={-100}
                      duration={1000}
                    >
                      {title}
                    </Link>
                  ) : (
                    <a target="_blank" rel="noreferrer" href={url}>
                      {title}
                    </a>
                  )}
                </Styled.MotionItem>
              ) : (
                <Button
                  target="_blank"
                  href={url}
                  rel="noreferrer"
                  bg="primary"
                  key={title}
                  className="item"
                  as="a"
                >
                  {title}
                </Button>
              )))}
          <DarkModeToggle />
        </Styled.MotionItemContainr>
      </Styled.MotionDiv>
    </Styled.HeaderContainer>
  </Styled.Header>
);
