import { css } from '../../../stitches.config';

export const widthVariants = css({
  variants: {
    maxWidth: {
      xxs: {
        maxWidth: '$xxs',
      },
      xs: {
        maxWidth: '$xs',
      },
      sm: {
        maxWidth: '$sm',
      },
      md: {
        maxWidth: '$md',
      },
      lg: {
        maxWidth: '$lg',
      },
      xl: {
        maxWidth: '$xl',
      },
      xxl: {
        maxWidth: '$xxl',
      },
    },

  },
});
