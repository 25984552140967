import React from 'react';
import { styled } from '../../../stitches.config';
import { backgroundColorVariants, flexVariants, marginVariants } from '../../styles/variants';

export const Wrapper = styled(
  'div',
  {
    display: 'block',
    transition: 'background-color 0.3s',
    backgroundColor: '$background',
  },
  backgroundColorVariants,
  flexVariants,
  marginVariants,
);
