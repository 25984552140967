import React from 'react';
import { styled } from '../../../stitches.config';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { Wrapper } from '../Wrapper';

const Layout = ({
  children, nolinks,
}) => (
  <StyledLayout>
    <Header nolinks={nolinks} />
    {children}
    <Wrapper>
      <Footer />
    </Wrapper>
  </StyledLayout>
);

const StyledLayout = styled('main', {
  mt: '$7',
});

export default Layout;
