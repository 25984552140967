import { styled } from '../../../stitches.config';
import { Container } from '../Container';

export const Header = styled('nav', {
  width: '100%',
  position: 'fixed',
  top: '0',
  zIndex: 2,
  height: '128px',
  backgroundColor: '$background',
  transition: 'background-color 0.3s',
});
export const HeaderContainer = styled(Container, {
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100%',
  padding: '0',
  display: 'flex',
});

export const MotionItemContainr = styled('div', {
  fontWeight: '$5',
  fontSize: '$3',
  width: '100%',
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  '.item': { '@lg': { display: 'none' } },
});

export const MotionItem = styled('div', {
  position: 'relative',
  a: { color: '$text', textDecoration: 'none' },
  '&:hover': { cursor: 'pointer' },
});

export const MotionDiv = styled('div', {
  width: '100%',
  maxWidth: '$sm',
  '@lg': { maxWidth: '100px' },

});

export const MotionLogo = styled('div', {
  svg: {
    width: '110px',
    height: '110px',
    path: {
      stroke: '$primary',
      fill: '$primary',
      strokeWidth: 1,
      strokeDasharray: '800',
      strokeDashoffset: 800,
      animation: 'offset 0.5s linear forwards, fill-it 0.8s 0.5s forwards',
    },
  },
  '@lg': { paddingLeft: '10px' },
});

export const SvgContainer = styled('div', {
  height: '22px',
  width: '22px',
  paddingLeft: '20px',
  '@md': { paddingLeft: '0px', paddingRight: '20px' },
  input: { display: 'none' },
  svg: {
    color: '$success',
    height: '100%',
    width: '100%',
    transition: 'fill 0.6s',
    '&:hover': { cursor: 'pointer' },
  },

});
