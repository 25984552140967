import { css } from '../../../stitches.config';

export const marginVariants = css({
  variants: {
    m: {
      auto: { m: 'auto' },
      0: { m: '0px' },
      1: { m: '$1' },
      2: { m: '$2' },
      3: { m: '$3' },
      4: { m: '$4' },
      5: { m: '$5' },
      6: { m: '$6' },
      7: { m: '$7' },
      8: { m: '$8' },
    },
    my: {
      0: { my: '0px' },
      1: { my: '$1' },
      2: { my: '$2' },
      3: { my: '$3' },
      4: { my: '$4' },
      5: { my: '$5' },
      6: { my: '$6' },
      7: { my: '$7' },
      8: { my: '$8' },
    },
    mx: {
      auto: { mx: 'auto' },
      0: { mx: '0px' },
      1: { mx: '$1' },
      2: { mx: '$2' },
      3: { mx: '$3' },
      4: { mx: '$4' },
      5: { mx: '$5' },
      6: { mx: '$6' },
      7: { mx: '$7' },
      8: { mx: '$8' },
    },
    ml: {
      0: { ml: '0px' },
      1: { ml: '$1' },
      2: { ml: '$2' },
      3: { ml: '$3' },
      4: { ml: '$4' },
      5: { ml: '$5' },
      6: { ml: '$6' },
      7: { ml: '$7' },
      8: { ml: '$8' },
    },
    mr: {
      0: { mr: '0px' },
      1: { mr: '$1' },
      2: { mr: '$2' },
      3: { mr: '$3' },
      4: { mr: '$4' },
      5: { mr: '$5' },
      6: { mr: '$6' },
      7: { mr: '$7' },
      8: { mr: '$8' },
    },
    mt: {
      0: { mb: '0px' },
      1: { mb: '$1' },
      2: { mb: '$2' },
      3: { mb: '$3' },
      4: { mb: '$4' },
      5: { mb: '$5' },
      6: { mb: '$6' },
      7: { mb: '$7' },
      8: { mb: '$8' },
    },
    mb: {
      0: { mb: '0px' },
      1: { mb: '$1' },
      2: { mb: '$2' },
      3: { mb: '$3' },
      4: { mb: '$4' },
      5: { mb: '$5' },
      6: { mb: '$6' },
      7: { mb: '$7' },
      8: { mb: '$8' },
    },
  },
});
