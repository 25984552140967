import React from 'react';
import { styled } from '../../../stitches.config';
import { paddingVariants } from '../../styles/variants';

export const Section = styled(
  'section',
  {
    maxWidth: '$xl',
    margin: '0 auto',
    '@xl': {
      paddingLeft: '$3',
      paddingRight: '$3',
    },
    '@lg': {
      px: '$3',
    },
  },
  paddingVariants,
);
